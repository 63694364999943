<template>
  <div v-loading="loading">
    <div class="top clearfix">
      <div class="left">
        <i class="iconfont icon-bianji-icon"></i>
        <span
          >有任何问题或者建议，请告诉我们。您的建议将会让我们做的更好！</span
        >
      </div>
      <el-button
        class="right"
        @click="save"
        :disabled="$store.getters.permissionsStr('新增')"
      >
        <i class="iconfont icon-tijiao-icon blueColor"></i>
        <span>提交</span>
      </el-button>
    </div>
    <div class="content">
      <div class="left-box clearfix" style="float: left;">
        <p>详细描述：</p>
        <el-input type="textarea" v-model="content" :rows="12"></el-input>
        <p>联系方式：</p>
        <el-input
          type="text"
          v-model="contact_way"
          placeholder="请输入邮箱/手机号/QQ号/微信号"
        ></el-input>
      </div>
      <div class="right-box clearfix" style="float: right;">
        <div>
          联系客服:
        </div>
        <p>
          <i
            class="iconfont icon-dianhua"
            style="color: #30c25a; margin-right: 5px;"
          ></i>
          <span>电话：010-58773281</span>
        </p>
        <p>
          <i
            class="iconfont icon-QQ"
            style="color: #1e9fff; margin-right: 5px;"
          ></i>
          <span>QQ ：991721919 </span>
        </p>
        <p>
          <i
            class="iconfont icon-youxiang"
            style="color: #f5a623; margin-right: 5px;"
          ></i>
          <span>邮箱：lixiao@chinacpt.com</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      contact_way: '',
      content: '',
      loading: false,
    };
  },
  methods: {
    save() {
      if (this.content == '') {
        return this.$utils.message({
          type: 'error',
          message: '描述不能为空',
        });
      } else if (this.contact_way == '') {
        return this.$utils.message({
          type: 'error',
          message: '联系方式不能为空',
        });
      }
      this.loading = true;
      this.$axios
        .post(
          '/p/feedback/add',
          this.$qs({
            content: this.content,
            contact_way: this.contact_way,
          })
        )
        .then((res) => {
          if (res.data.code == 0) {
            this.$utils.message({
              type: 'success',
              message: res.data.message,
            });
            this.content = '';
            this.contact_way = '';
          } else {
            this.$utils.message({
              type: 'error',
              message: res.data.message,
            });
          }
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.top {
  box-sizing: border-box;
  padding: 20px;
  .left {
    float: left;
    i {
      font-size: 22px;
      font-family: MicrosoftYaHei;
      color: rgba(75, 179, 255, 1);
      line-height: 24px;
    }
    span {
      font-size: 18px;
      font-family: MicrosoftYaHei;
      color: rgba(75, 179, 255, 1);
      line-height: 24px;
    }
  }
  .right {
    float: right;
  }
}
.left-box {
  width: 70%;
  margin-left: 40px;
  p {
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: rgba(98, 98, 98, 1);
    line-height: 21px;
    margin: 20px 0;
  }
}
.right-box {
  margin-right: 60px;
  margin-top: 60px;
  div {
    font-size: 18px;
    font-family: MicrosoftYaHei;
    color: rgba(54, 54, 54, 1);
    line-height: 24px;
  }
  p {
    line-height: 34px;
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: rgba(107, 107, 107, 1);
  }
}
</style>
